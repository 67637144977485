<template>
  <div class="entrustChildForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      :footerShow="footerShow"
      @update="update"
    >
      <!-- 选择预约形式 start -->
      <el-form-item
        label="选择预约形式"
        :rules="[
          { required: true, message: '请选择预约形式', trigger: 'blur' },
        ]"
        prop="type"
      >
        <v-select
          :width="350"
          placeholder="请选择预约形式"
          :disabled="!footerShow"
          v-model="form.type"
          :options="reservationListOps"
        ></v-select>
      </el-form-item>
      <!-- 选择预约形式 end -->
      <!--监控名称  -->
      <el-form-item
        label="监控名称"
        :rules="[
          { required: true, message: '请输入监控名称', trigger: 'blur' },
        ]"
        prop="monitoringId"
      >
        <v-select
          placeholder="请选择监控名称"
          :width="350"
          :disabled="!footerShow"
          :options="monitoringList"
          v-model="form.monitoringId"
        ></v-select>
      </el-form-item>

      <el-form-item
        v-if="form.type == 1"
        label="课程日期"
        :rules="[
          { required: true, message: '请选择课程日期', trigger: 'blur' },
        ]"
        prop="dayName"
      >
        <v-date-picker
          :disabled="!footerShow"
          type="date"
          v-model="form.dayName"
          formatValue="yyyy-MM-dd"
          placeholder="请选择课程日期"
          :width="350"
        ></v-date-picker>
      </el-form-item>

      <el-form-item
        :label="form.type == 1 ? '报名时间' : '预约日期'"
        :rules="[
          { required: true, message: '请选择报名时间', trigger: 'change' },
        ]"
        prop="applyEndTime"
      >
        <v-date-picker
          :disabled="!footerShow"
          v-model="createTime"
          formatValue="yyyy-MM-dd"
          format="yyyy-MM-dd"
          startPlaceholder="报名开始时间"
          endPlaceholder="报名结束时间"
        />
      </el-form-item>

      <el-form-item
        v-if="form.type == 1"
        label="可预约人数"
        :rules="[
          { required: true, message: '请输入可预约人数', trigger: 'blur' },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="applicantsMax"
      >
        <v-input
          :disabled="!footerShow"
          placeholder="请输入可预约人数"
          v-model="form.applicantsMax"
          :width="350"
        ></v-input>
      </el-form-item>

      <el-form-item
        v-if="form.type == 1"
        label="课程时间"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="timeDuring"
      >
        <v-radio
          :disabled="!footerShow"
          :radioObj="timeDuringOps"
          v-model="form.timeDuring"
        ></v-radio>
      </el-form-item>
      <!-- 课程预约 start-->
      <el-form-item
        v-if="form.type == 1"
        label="活动场次"
        :rules="[
          { required: true, message: '请输入活动场次', trigger: 'blur' },
        ]"
        prop="detailList"
      >
        <div class="times">
          <div
            class="areaTimes"
            v-for="(item, index) in form.detailList"
            :key="index"
          >
            <div class="areaTimes-l">
              <div class="item">
                <div class="item-l">场次时间</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请选择场次开始时间',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`detailList.${index}.activityBeginTime`"
                  >
                    <v-time
                      :disabled="!footerShow"
                      v-model="item.activityBeginTime"
                      valueFormat="HH:mm"
                      format="HH:mm"
                      :pickerOptions="null"
                      :width="190"
                    ></v-time>
                    <el-form-item
                      style="margin-left: 20px"
                      :rules="[
                        {
                          required: true,
                          message: '请选择场次结束时间',
                          trigger: 'blur',
                        },
                      ]"
                      :prop="`detailList.${index}.activityEndTime`"
                    >
                      <v-time
                        :disabled="!footerShow"
                        v-model="item.activityEndTime"
                        valueFormat="HH:mm"
                        format="HH:mm"
                        :pickerOptions="null"
                        :width="190"
                      ></v-time>
                    </el-form-item>
                  </el-form-item>
                </div>
              </div>
              <div class="item">
                <div class="item-l">课程名称</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请输入课程名称',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`detailList.${index}.activityName`"
                  >
                    <v-input
                      :disabled="!footerShow"
                      placeholder="请输入课程名称"
                      v-model="item.activityName"
                      :width="400"
                      :maxlength="50"
                    ></v-input>
                  </el-form-item>
                </div>
              </div>
              <div class="item">
                <div class="item-l">课程内容</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请输入课程内容',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`detailList.${index}.activityDetail`"
                  >
                    <v-input
                      :disabled="!footerShow"
                      type="textarea"
                      :maxlength="200"
                      placeholder="请输入课程内容"
                      v-model="item.activityDetail"
                      :width="400"
                    ></v-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="areaTimes-r">
              <div class="opera">
                <v-button
                  text="删除"
                  v-if="index != 0"
                  :disabled="!footerShow"
                  @click="outRoom(item, index)"
                  type="warning"
                ></v-button>
                <v-button
                  :disabled="!footerShow"
                  v-if="index == form.detailList.length - 1"
                  text="新增"
                  @click="addRoom"
                ></v-button>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <!-- 课程预约 end-->
      <!-- 托幼预约 start -->
      <template v-if="form.type == 2">
        <el-form-item label="选择预约星期" prop="weekName" :rules="oRules">
          <el-checkbox-group v-model="openDaysList" :disabled="!footerShow">
            <el-checkbox label="1">周一</el-checkbox>
            <el-checkbox label="2">周二</el-checkbox>
            <el-checkbox label="3">周三</el-checkbox>
            <el-checkbox label="4">周四</el-checkbox>
            <el-checkbox label="5">周五</el-checkbox>
            <el-checkbox label="6">周六</el-checkbox>
            <el-checkbox label="7">周日</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item
          label="选择场次"
          :rules="[{ required: true, message: '请选择场次', trigger: 'blur' }]"
          prop="kindergartensList"
        >
          <v-button
            :disabled="!footerShow"
            size="medium"
            type="success"
            icon="el-icon-plus"
            text="添加场次"
            @click="showScheduleAddView"
          >
          </v-button>
          <div style="width: 600px">
            <el-table
              class="el-table"
              :data="form.kindergartensList"
              element-loading-text="Loading"
              border
              highlight-current-row
            >
              <el-table-column
                type="index"
                label="排序"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column
                label="星期"
                align="center"
                width="100"
                prop="weekName"
              >
                <template slot-scope="{ row }">
                  {{ weekArrMap[row.weekName] }}
                </template>
              </el-table-column>
              <el-table-column label="开始时间" align="center" width="100">
                <template slot-scope="scope">
                  {{ scope.row.beginTime }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="结束时间" width="100">
                <template slot-scope="scope">
                  {{ scope.row.endTime }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="可预约人数"
                width="100"
                prop="applicantsMax"
              >
              </el-table-column>

              <el-table-column
                align="center"
                label="操作"
                class-name="small-padding"
              >
                <template slot-scope="scope">
                  <div class="dialogBottomBtn">
                    <v-button
                      type="primary"
                      size="mini"
                      :disabled="!footerShow"
                      v-if="scope.row.canEdit == undefined || scope.row.canEdit"
                      @click="showScheduleEditView(scope.$index)"
                      text="编辑"
                    >
                    </v-button>
                    <v-button
                      size="mini"
                      type="danger"
                      :disabled="!footerShow"
                      @click="deleteScheduleTempData(scope.$index)"
                      text="删除"
                    >
                    </v-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </template>
      <!-- 托幼预约 end -->
    </form-panel>
    <!-- 添加场次弹窗 -->
    <v-dialog
      title="场次信息编辑"
      v-model="dialogFormVisible"
      width="50%"
      @confirm="saveScheduleTempData"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="星期">
          <v-select
            v-model="tempScheduleObj.weekName"
            :options="weekOptins"
          ></v-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <v-time-old v-model="tempScheduleObj.beginTimeDate"></v-time-old>
        </el-form-item>

        <el-form-item label="结束时间">
          <v-time-old v-model="tempScheduleObj.endTimeDate"></v-time-old>
        </el-form-item>
        <el-form-item label="可预约人数">
          <v-input v-model="tempScheduleObj.applicantsMax"></v-input>
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import { mobileRegular } from "@/utils/regular";
import { reservationListOps, weekArrOps, weekArrMap } from "./map";
import {
  getClassDetailForManageUrl,
  addClassInfoUrl,
  updateClassInfoAndDetailsUrl,
  getMonitoringList,
} from "./api.js";
export default {
  name: "partyMemberForm",
  data() {
    let validay = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请选择开放星期"));
      } else {
        callback();
      }
    };
    return {
      width: 250,
      submitConfig: {
        queryUrl: getClassDetailForManageUrl,
        submitUrl: "",
      },
      timeDuringOps: [
        { name: 1, value: "上午" },
        { name: 2, value: "下午" },
      ],
      form: {
        detailId: undefined,
        scheduleId: undefined,
        updateUser: undefined,
        dayName: "",
        timeDuring: "",
        applyBeginTime: "",
        applyEndTime: "",
        applicantsMax: "",
        type: 1,
        weekName: null, // 开放的星期
        monitoringId: "", // 监控id
        detailList: [
          {
            activityBeginTime: "00:00",
            activityEndTime: "00:00",
            activityName: "",
            activityDetail: "",
          },
        ],
        kindergartensList: [],
      },
      reservationListOps: reservationListOps(), // 课程预约形式下拉数据
      oRules: [
        {
          required: true,
          message: "请选择开放星期",
          trigger: "change",
        },
        {
          validator: validay,
          trigger: "change",
        },
      ],
      tempScheduleObj: {
        index: null,
        weekName: null, // 星期几
        beginTime: null, //开始时间
        beginTimeDate: null,
        endTimeDate: null,
        endTime: null, // 结束时间
        applicantsMax: null, //预约人数
      }, //添加场次数据
      dialogFormVisible: false, // 添加场次弹窗控制
      weekArrOps: weekArrOps(), //星期下拉数据
      weekArrMap,
      footerShow: true, // 控制表单底部提交按钮
      monitoringList: [], //监控列表
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.form.applyBeginTime && !this.form.applyEndTime
          ? []
          : [this.form.applyBeginTime, this.form.applyEndTime];
      },
      set(val) {
        [this.form.applyBeginTime, this.form.applyEndTime] = val || ["", ""];
      },
    },
    openDaysList: {
      get() {
        return this.form.weekName && this.form.weekName.length > 0
          ? this.form.weekName.split(",")
          : [];
      },
      set(val) {
        this.form.weekName = val.join(",");
      },
    },
    weekOptins() {
      let arr = [];
      if (this.openDaysList.length > 0) {
        this.weekArrOps.forEach((item) => {
          this.openDaysList.forEach((item1) => {
            if (item.value == item1) {
              arr.push(item);
            }
          });
        });
      }
      return arr;
    },
  },
  mounted() {
    const { id } = this.$route.query;
    this.form.detailId = id;
    this.submitConfig.submitUrl = id
      ? updateClassInfoAndDetailsUrl
      : addClassInfoUrl;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ scheduleId: id });
    }

    this.getMonitoringList();
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    // 获取监控信息
    getMonitoringList() {
      this.$axios.get(getMonitoringList).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            item.value = item.monitoringId;
            item.label = item.monitoringName;
            this.monitoringList.push(item);
          });
        }
      });
    },
    //添加场次
    showScheduleAddView() {
      this.tempScheduleObj = {
        index: null,
        weekName: null, // 星期几
        beginTime: null, //开始时间
        beginTimeDate: null,
        endTimeDate: null,
        endTime: null, // 结束时间
        applicantsMax: null, //预约人数
      };
      this.dialogFormVisible = true;
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
        // this.form.isPartyMember = this.form.isPartyMember
        //   ? String(this.form.isPartyMember)
        //   : "";
        // this.form.isCadre = this.form.isCadre ? String(this.form.isCadre) : "";
      });
      // 有参与人不可编辑
      if (data.applicantsNum !== undefined && data.applicantsNum > 0) {
        this.footerShow = false;
      }
      // 回显开放日期
      if (this.form.weekName && this.form.weekName.length > 0) {
        this.openDaysList = this.form.weekName.split(",");
      } else {
        this.openDaysList = [];
      }
      // 回显监控信息
      if (this.form.monitoringId) {
        this.form.monitoringId = this.form.monitoringId
          ? String(this.form.monitoringId)
          : "";
      }
    },
    submitBefore() {
      let params = {
        insertUser: !this.form.detailId
          ? this.$store.state.app.userInfo.userId
          : undefined,
        updateUser: this.form.detailId
          ? this.$store.state.app.userInfo.userId
          : undefined,
      };
      return {
        ...this.form,
        ...params,
      };
    },
    submitSuccess() {
      return true;
    },
    /* 活动场次 */
    async outRoom(item, index) {
      this.form.detailList.splice(index, 1);
    },
    addRoom() {
      this.form.detailList.push({
        activityBeginTime: "00:00",
        activityEndTime: "00:00",
        activityName: "",
        activityDetail: "",
      });
    },
    //编辑
    showScheduleEditView(index) {
      let data = this.form.kindergartensList[index];
      let now = new Date().format("yyyy-MM-dd");
      if (data.beginTime.length == 5) {
        data.beginTimeDate = now + " " + data.beginTime + ":00";
      } else {
        data.beginTimeDate = data.beginTime;
      }
      if (data.endTime.length == 5) {
        data.endTimeDate = now + " " + data.endTime + ":00";
      } else {
        data.endTimeDate = data.endTime;
      }
      this.tempScheduleObj = {
        index: index,
        weekName: data.weekName,
        beginTime: data.beginTime,
        endTime: data.endTime,
        beginTimeDate: new Date(data.beginTimeDate),
        endTimeDate: new Date(data.endTimeDate),
        applicantsMax: data.applicantsMax,
      };
      this.dialogFormVisible = true;
    },
    //删除场次
    deleteScheduleTempData(index) {
      let data = this.form.kindergartensList[index];
      this.$confirm(
        "确定要删除" + data.beginTime + "~" + data.endTime + "?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          this.form.kindergartensList.splice(index, 1);
        })
        .catch(() => {});
    },
    // 弹窗确定
    saveScheduleTempData() {
      if (!this.tempScheduleObj.weekName) {
        this.$message.error("请选择星期");
        return;
      }
      if (!this.tempScheduleObj.beginTimeDate) {
        this.$message.error("请选择开始时间");
        return;
      }
      if (!this.tempScheduleObj.endTimeDate) {
        this.$message.error("请选择结束时间");
        return;
      }
      if (!this.tempScheduleObj.applicantsMax) {
        this.$message.error("请选择可预约人数");
        return;
      }

      if (
        this.tempScheduleObj.beginTimeDate >= this.tempScheduleObj.endTimeDate
      ) {
        this.$message.error("开始时间必须小于结束时间");
        return;
      }

      if (this.tempScheduleObj.beginTimeDate instanceof Date) {
      } else {
        this.tempScheduleObj.beginTimeDate = new Date(
          this.tempScheduleObj.beginTimeDate
        );
      }
      if (this.tempScheduleObj.endTimeDate instanceof Date) {
      } else {
        this.tempScheduleObj.endTimeDate = new Date(
          this.tempScheduleObj.endTimeDate
        );
      }

      this.tempScheduleObj.beginTime =
        this.tempScheduleObj.beginTimeDate.format("HH:mm");
      this.tempScheduleObj.endTime =
        this.tempScheduleObj.endTimeDate.format("HH:mm");
      if (this.tempScheduleObj.beginTime && this.tempScheduleObj.endTime) {
        for (let i = 0; i < this.form.kindergartensList.length; i++) {
          if (i != this.tempScheduleObj.index) {
            let scheduleObj = this.form.kindergartensList[i];
            if (
              (this.tempScheduleObj.beginTime <= scheduleObj.beginTime &&
                this.tempScheduleObj.endTime >= scheduleObj.beginTime) ||
              (this.tempScheduleObj.beginTime <= scheduleObj.endTime &&
                this.tempScheduleObj.endTime >= scheduleObj.endTime) ||
              (this.tempScheduleObj.beginTime >= scheduleObj.beginTime &&
                this.tempScheduleObj.endTime <= scheduleObj.endTime)
            ) {
              if (this.tempScheduleObj.index == null) {
                if (scheduleObj.weekName == this.tempScheduleObj.weekName) {
                  this.$message.error("同星期场次的时间不能重叠");
                  return;
                }
              } else {
                if (scheduleObj.weekName == this.tempScheduleObj.weekName) {
                  this.$message.error("同星期场次的时间不能重叠");
                  return;
                }
              }
            }
          } else {
            for (let i = 0; i < this.form.kindergartensList.length; i++) {
              if (i != this.tempScheduleObj.index) {
                let scheduleObj = this.form.kindergartensList[i];
                if (
                  (this.tempScheduleObj.beginTime <= scheduleObj.beginTime &&
                    this.tempScheduleObj.endTime >= scheduleObj.beginTime) ||
                  (this.tempScheduleObj.beginTime <= scheduleObj.endTime &&
                    this.tempScheduleObj.endTime >= scheduleObj.endTime) ||
                  (this.tempScheduleObj.beginTime >= scheduleObj.beginTime &&
                    this.tempScheduleObj.endTime <= scheduleObj.endTime)
                ) {
                  if (this.tempScheduleObj.index == null) {
                    if (scheduleObj.weekName == this.tempScheduleObj.weekName) {
                      this.$message.error("同星期场次的时间不能重叠");
                      return;
                    }
                  } else {
                    if (scheduleObj.weekName == this.tempScheduleObj.weekName) {
                      this.$message.error("同星期场次的时间不能重叠");
                      return;
                    }
                  }
                }
              }
            }
          }
        }
      } else {
      }
      if (this.tempScheduleObj.index == null) {
        this.tempScheduleObj["index"] = this.form.kindergartensList.length;
        this.form.kindergartensList.push(this.tempScheduleObj);
      } else {
        this.$set(
          this.form.kindergartensList,
          this.tempScheduleObj.index,
          this.tempScheduleObj
        );
      }
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.entrustChildForm {
  box-sizing: border-box;
  height: 100%;

  .areaTimes {
    display: flex;
    align-items: flex-end;

    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .areaTimes-l {
      margin-right: 20px;
    }
    .areaTimes-r {
      height: 57px;
      /deep/ .v-button {
        margin-right: 10px;
      }
    }
    .item {
      display: flex;
      align-content: center;
      margin: 20px 0;
      .item-l {
        margin-right: 10px;
      }
      .item-r {
        display: flex;
      }
    }
  }
}
</style>
